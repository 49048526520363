<template>
    <v-card tile elevation="4" class="pa-0">
        <v-toolbar short flat color="white">
            <v-toolbar-title class="indigo--text">{{label}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon color="indigo" @click="openCreateEnterpriseDialog">
                <font-awesome-icon :icon="['fas', 'plus']" style="font-size: 20px;" fixed-width/>
            </v-btn>
            <v-menu offset-y left>
                <template v-slot:activator="{ on }">
                    <v-btn icon color="indigo" v-on="on">
                        <font-awesome-icon :icon="['far', 'ellipsis-v']" style="font-size: 20px;" fixed-width/>
                    </v-btn>
                    <!-- <v-tab v-on="on" class="indigo--text">
                        <Avatar :attr="mainProfile" :size="36"/>
                        <font-awesome-icon icon="caret-down" class="pl-2 indigo--text" fixed-width size="1x"/>
                    </v-tab> -->
                </template>
                <v-list class="ma-0 pa-0">
                    <v-list-item-group>
                    <v-list-item :to="{ name: 'service-admin-enterprise-settings' }">
                        <!-- <v-list-item-content> -->
                            <v-list-item-title>Settings</v-list-item-title>
                        <!-- </v-list-item-content> -->
                    </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-menu>
        </v-toolbar>
        <!-- <v-divider></v-divider> -->
        <v-list dense nav>
            <v-list-item v-if="list.length === 0">No enterprises yet.</v-list-item>
            <v-list-item v-for="item in list" v-bind:key="item.id" class="my-2" @click="onClickItem(item.id)">
                <v-list-item-content class="text-start">
                    <EnterpriseListItem :attr="item" :link="!selectOne"></EnterpriseListItem>
                </v-list-item-content>
            </v-list-item>
        </v-list>
        <CreateEnterpriseDialog v-model="createEnterpriseDialogVisible" @cancel="createEnterpriseDialogVisible = false" @created="onCreatedEnterprise"/>
    </v-card>
</template>

<script>
import EnterpriseListItem from '@/components/list-item/EnterpriseListItem.vue';
import CreateEnterpriseDialog from '@/components/service-dashboard/CreateEnterpriseDialog.vue';
import { isValidName, compact } from '@/sdk/input';
// import { client } from '@/client';

export default {
    components: {
        EnterpriseListItem,
        CreateEnterpriseDialog,
    },

    props: {
    },

    data() {
        return {
            list: [],
            createEnterpriseDialogVisible: false,
            // create enterprise
            displayCreateEnterprise: false,
            createEnterpriseForm: null,
            newEnterpriseName: null,
            newEnterpriseNameRules: [
                (v) => !!v || 'Display name is required',
                (v) => !v || isValidName(compact(v)) || 'Enterprise name is required',
            ],
            // add enterprise
            addEnterpriseChoices: [],
            addEnterpriseId: null,
        };
    },

    computed: {
        id() {
            return this.policyId;
        },
        label() {
            if (this.selectOne) {
                return 'Select an enterprise';
            }
            switch (this.list.length) {
            case 0:
                return 'No enterprises';
            case 1:
                return '1 enterprise';
            default:
                return `${this.list.length} enterprises`;
            }
        },
    },

    watch: {
        // displayCreateEnterprise(value) {
        //     if (value && (this.add || this.create)) {
        //         this.initAddEnterpriseChoices();
        //     }
        // },
    },

    methods: {
        async loadEnterpriseList() {
            try {
                this.$store.commit('loading', { loadEnterpriseList: true });
                const response = await this.$client.main().service.getEnterpriseList();
                if (response?.list) {
                    this.list = response.list;
                }
            } catch (err) {
                console.error('loadEnterpriseList failed', err);
            } finally {
                this.$store.commit('loading', { loadEnterpriseList: false });
            }
        },
        onClickItem(enterpriseId) {
            // if (this.selectOne) {
            //     this.$emit('selected', { enterpriseId });
            // }
            this.$router.push({ name: 'enterprise-dashboard', params: { enterpriseId } });
        },
        openCreateEnterpriseDialog() {
            this.createEnterpriseDialogVisible = true;
        },
        onCreatedEnterprise() {
            this.createEnterpriseDialogVisible = false;
            this.loadEnterpriseList();
        },
        // async createEnterprise() {
        //     this.error = false;
        //     console.log('createEnterprise');
        //     const request = {
        //         // name: this.name,
        //         name: this.newEnterpriseName,
        //         // agreeToTerms: this.isAgreeToTermsChecked,
        //         // interactionId: this.interactionId, // will be present if enterprise arrives from an existing interaction, such as clicking on a specific link to get started and we can use this to redirect the enterprise to an appropriate location after enterprise is created
        //     };
        //     const response = await this.$client.enterprise.create(request);
        //     console.log('createEnterprise response: %o', response);
        //     const { isCreated, id, error } = response;
        //     if (isCreated) {
        //         const enterprise = { id, ...request };
        //         this.list.push(enterprise);
        //         this.$emit('created-enterprise', enterprise);
        //         this.$emit('added-enterprise', enterprise);
        //         this.displayCreateEnterprise = false;
        //         this.newEnterpriseName = null;
        //     } else if (error) {
        //         this.error = error;
        //     } else {
        //         this.error = 'Request failed';
        //     }
        // },
        // async addEnterprise() {
        //     const enterprise = { id: this.addEnterpriseId, label: this.addEnterpriseChoices.find((item) => item.value === this.addEnterpriseId).text };
        //     this.list.push(enterprise);
        //     this.$emit('added-enterprise', enterprise);
        //     this.displayCreateEnterprise = false;
        //     this.addEnterpriseId = null;
        // },
        // async initAddEnterpriseChoices() {
        //     // create a map of which enterprise ids are already in the list
        //     const enterpriseMap = {};
        //     this.list.forEach((item) => {
        //         enterpriseMap[item.id] = true;
        //     });
        //     // when we show the add/create enterprise dialog, load the list of available enterprises to add to the policy (list of all enterprises less the ones already in the policy)
        //     const result = await this.$client.service.getEnterpriseList();
        //     console.log(`EnterpriseViewList.vue fetch enterprises result: ${JSON.stringify(result)}`);
        //     if (result && result.list) {
        //         // filter the results to remove enterprises already in the policy, then convert the available enterprises to a choice list for the v-select [ { text, value }, ... ]
        //         this.addEnterpriseChoices = result.list.filter((item) => !enterpriseMap[item.id]).map((item) => ({ text: item.name, value: item.id }));
        //     }
        // },
    },

    mounted() {
        this.loadEnterpriseList();
    },
};
</script>
